import React, { useState, useEffect, useMemo } from 'react';
import './App.css';
import 'bulma/css/bulma.css'

import Type from './components/Type'
import Survey from './components/Survey'
import Question from './components/Question'
import OpenQuestion from './components/OpenQuestion'
import AppLayout from './components/AppLayout'
import Loading from './components/Loading'
import UnsupportedBrowserMessage from './components/UnsupportedBrowserMessage';
import Config from './config'
import queryString from 'query-string'

import { HOST_PREFIX, HOST_SUFFIX, NEW_HOST_URL, CONFIGS_URL } from './constants';

import { detect } from 'detect-browser';


function useLSCache(item, expiration){
    const cached = maybeParseJSON(localStorage.getItem(item)) || {};
    if (cached.timestamp){
        let diff = (new Date() - Date.parse(cached.timestamp)) / 1000;
        if (diff > expiration) {
            localStorage.removeItem(item);
            return null;
        }
    }

    const setCache = useMemo(() => (value) => {
        localStorage.setItem(item, JSON.stringify(value));
    }, [item]);

    return [cached, setCache];
}

function useConfig(institution){
    const [config, setConfig] = useState({});
    const [loadingConfig, setLoadingConfig] = useState(true);

    useEffect(() => {
        if(institution) {
            setLoadingConfig(true);
            fetch(`${CONFIGS_URL}/${institution}.json`).then(response => response.json()).then(config => {
                console.log('config', config);
                setConfig(config);
                setLoadingConfig(false);
            });
        }
    }, [institution]);

    return [config, loadingConfig];
}

function App(props){
    const {
        config,
        state,
        type,
        setState,
        setValue,
        setValues,
        openButton,
        didAlreadyAnswer,
        browser,
        loadingConfig,
        hideLanguageBar,
        languages,
    } = useAppHooks(props);

    function renderThankYouText(system){
        return <h1 className="title is-4"> {config.thankyouText[state.language]} </h1>;
    }

    function renderSurvey(system, labels){
        var label_list = config.labels.map((l) => l[state.language])
        return <Survey system={system} labels={label_list} select={setValue}/>
    }

    function renderTypes(types){
        return <Type options={types} language={state.language} select={setValues}/>
    }

    function renderOpenQuestion(options){
        let btnText = openButton[state.language] || "Submit"
        return <OpenQuestion name="openAnswer" select={setValue} buttonText={btnText}/>
    }

    function renderQuestion(options){
        let option_list = options.map((op) => { return {id : op.id, text: op.option[state.language]} })
        return <Question options={option_list} select={setValue}/>
    }

    function determineRoute(){
        let route = null, prompt = null;
        const {
            domain,
            language,
            openAnswer,
            reason,
            rating,
            sending,
            teller,
            typeId,
            type_selected,
        } = state;

        if ((domain == null || teller == null ) || didAlreadyAnswer){
            route = renderThankYouText();
        } else if(sending){
            prompt = <button className="button loading is-loading is-large">Loading</button>;
            route = null;
        } else if (typeId == null){
            prompt = config.typeText[language];
            route = renderTypes(config.questions)
        } else if (rating == null){
            prompt = config.prompt[language];
            route = renderSurvey(config.system, config.labels);
        } else if (reason == null){
            if(typeId){
                let type_from_url = Object.values(config.questions).filter(obj => {
                    return obj.typeId === parseInt(typeId);
                })[0] || Object.values(config.questions)[0]
                prompt = type_from_url.title[language];
                route = renderQuestion(type_from_url["options"]);
            }
            else{
                prompt = type_selected.title[language];
                route = renderQuestion(type_selected["options"]);
            }
        } else if (openAnswer == null && config.openAnswer){
            prompt= config.openQuestionPrompt[language];
            route = renderOpenQuestion();
        } else {
            route = renderThankYouText();
        }

        return [route, prompt];
    }

    if (browser.name === "ie" && parseInt(browser.version) < 9){
        return <UnsupportedBrowserMessage />;
    }

    const [route, prompt] = loadingConfig ? [] : determineRoute();

    return loadingConfig ? (
        <Loading />
    ) : (
        <AppLayout
            css={config.css}
            hideLanguageBar={hideLanguageBar}
            languages={languages}
            setLanguage={language => setState({ language })}
            title={prompt}
        >{route}</AppLayout>
    );
}


function useAppHooks(props){
    const urlData = useMemo(() => ({
      ...queryString.parse(window.location.search),
      ...queryString.parse(window.location.hash)
    }), [window.location.hash, window.location.search]);
    const browser = useMemo(() => detect(), []);

    const [latest, setLatest] = useLSCache('latestSubmit', 300);
    const didAlreadyAnswer = !!(
        latest
        && latest.rating
        && latest.reason !== null && latest.reason !== undefined
        && (latest.openAnswer || latest.openAnswer === "")
    );

    const [config, loadingConfig] = useConfig(urlData.i);
    const [lastUpdate, setLastUpdate] = useState();

    const languages = config.languages || {"en": "English", "es": "Español"};
    const openButton = {"en": "Submit", "es": "Contestar"};
    let language = urlData.lng || 'es';

    if (!languages[language]) {
      language = Object.keys(languages)[0];
    }

    const { questions, system } = config;

    const type = questions && questions[Object.keys(questions)[0]];

    const [state, _setState] = useState({
        domain: urlData.i || null,
        ticket: urlData.ticket || null,
        typeId: urlData.t || null,
        teller: urlData.d || null,
        system,
        defaultRating: latest.rating ? latest.rating : (urlData.rating || null),
        rating : latest.rating || null,
        reason: (latest.reason === null || latest.reason === undefined) ? null : latest.reason,
        delta: 0,
        sending: false,
        answerId: latest.answerId || null,
        type_selected: null,
        language
    });
    const setState = (newState) => {
        _setState({...state, ...newState});
    };

    useEffect(() => {
        if(state.defaultRating && state.ticket && !state.rating){
            setValue('rating', state.defaultRating);
        }
    }, []);

    useEffect(() => {
        if (lastUpdate && canSendAnswer() && !state.sending) {
            sendAnswer();
        }
    }, [lastUpdate]);

    useEffect(() => {
        if(config.title){
            document.title = config.title[state.language];
        }
    }, [config.title])

    function setValue(key, value){
        setState({
            ...state,
            [key]: value
        });
        setLastUpdate(new Date());
    }

    function setValues(values){
        setState({
            ...state,
            ...values
        });
        setLastUpdate(new Date());
    }

    function canSendAnswer(){
        const { domain, teller, typeId, rating } = state;
        return (domain && teller && typeId) && !didAlreadyAnswer && rating !== null;
    }

    function sendAnswer(){
        setState({ ...state, sending: true })
        let url = `${HOST_PREFIX}${state.domain}${HOST_SUFFIX}api/survey/device/${state.teller}/answer2/`
        if (config.single_schema){
            url = `${NEW_HOST_URL}api/df/device/${state.teller}/answer`
        }
        
        fetch( url , {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(state),
        }).then((resp) => {
            return resp.json()
        }).then((json) => {
            console.log(json)
            if (config.single_schema){
                json = json.data
            }
            setState({answerId: json.id, sending: false})

            setLatest({
                ticket: state.ticket,
                rating: state.rating,
                reason: state.reason,
                openAnswer: state.openAnswer,
                timestamp: String(new Date()),
                answerId: json.id
            });
        }).catch((er) => {
            console.log(er)
            setState({sending: false})
        });
    }

    return {
        config,
        state,
        type,
        setState,
        setValue,
        setValues,
        openButton,
        didAlreadyAnswer,
        browser,
        loadingConfig,
        hideLanguageBar: language === urlData.lng,
        languages,
    };
}


const maybeParseJSON = text => {
    try {
        return JSON.parse(text);
    } catch {
        return undefined;
    }
}

export default App;
