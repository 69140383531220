
// fetch api constants
export const HOST_PREFIX = 'https://';
export const HOST_SUFFIX = '.damefeedback.com/';
export const NEW_HOST_URL = 'https://pf42lr9w68.execute-api.us-east-1.amazonaws.com/demo/';
// export const CONFIGS_URL = 'configs';
export const CONFIGS_URL = 'https://akcelita-artsense.s3.amazonaws.com/damefeedback/configs';


//url for browser:
//http://localhost:3000/#i=bppr&d=240
//http://localhost:3000/#i=bppr&d=56&t=1  si se quiere eliminar el step de el type